<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <b-row>
        <b-col cols="12">
          <b-card class="my-card m-0">
            <b-table
              striped
              small
              id="myTabless"
              bordered
              selectable
              selected-variant="primary"
              select-mode="single"
              responsive="sm"
              :items="documentRow"
              :fields="field"
              @row-clicked="clicked"
              @row-contextmenu="handleContextMenuEvent"
              head-row-variant="secondary"
            >
              <template #cell(checkbox)="row">
                <b-form-checkbox
                  v-model="row.detailsShowing"
                  plain
                  class="vs-checkbox-con"
                  @change="row.toggleDetails"
                >
                </b-form-checkbox>
              </template>
              <template #cell(document_id)="data">
                <div
                  @dblclick="getDocumentInfo(data)"
                  v-if="data.item.document_id"
                  class="reference-field"
                >
                  {{ data.item.document_id }}
                </div>
              </template>
              <template #cell(category)="data">
                <span v-if="data.item.category == 'payment'"> Пополнения </span>
                <span v-if="data.item.category == 'charge'"> Вычитания </span>
              </template>
            </b-table>
            <vue-context ref="menu">
              <li>
                <b-link
                  class="d-flex align-items-center"
                  v-b-modal="$route.name + 'CreateModal'"
                >
                  <feather-icon icon="PlusIcon" size="16" />
                  <span class="ml-75">Добавить</span>
                </b-link>
                <b-link class="d-flex align-items-center" v-b-modal.cancelModal>
                  <feather-icon icon="align-justify" size="16" />
                  <span class="ml-75">Отмена</span>
                </b-link>
              </li>
            </vue-context>
            <b-modal
              id="cancelModal"
              size="sm"
              title="Отменить"
              no-close-on-backdrop
              hide-footer
              no-enforce-focus
            >
              <b-row>
                <b-col cols="12">
                  <label for="">Комментарий <i style="color: red">*</i></label>
                  <b-form-textarea
                    v-model="cancelDocument.comment"
                  ></b-form-textarea>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col class="d-flex justify-content-end">
                  <b-button
                    variant="primary"
                    class="mr-1"
                    size="sm"
                    :disabled="cancelDocument.comment ? false : true"
                    @click="cancelDoc()"
                  >
                    Ок
                  </b-button>
                  <b-button size="sm" @click="close()"> Отмена </b-button>
                </b-col>
              </b-row>
            </b-modal>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <filter-modalVue
      :fields="fields"
      @sendToParent="sendToParent"
    ></filter-modalVue>
    <modal-payments @refresh="refresh"></modal-payments>
  </div>
</template>

<script>
import modalPayments from "@/views/component/Modal/ModalDocument/modalPayments.vue";
import VueContext from "vue-context";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    modalPayments,
    VueContext,
    ToastificationContent,
  },
  data() {
    return {
      drivers: undefined,
      order_id: this.$route.query.order_id,
      assigment: [],
      showPreloader: false,
      refreshTimer: null,
      documentRow: [],
      field: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "ID", sortable: true, thStyle: { width: "150px" } },
        {
          key: "division_name",
          label: "Подраздиления",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "phone_number",
          label: "Телефон",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "result",
          label: "Результат",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "expires_at",
          label: "Дата ",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "phone_number",
          label: "Номер",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "code",
          label: "Код",
          sortable: true,
          thStyle: { width: "130px" },
        },
        {
          key: "client_id",
          label: "ID клиента",
          sortable: true,
          thStyle: { width: "130px" },
        },
        {
          key: "type",
          label: "Тип",
          sortable: true,
          thStyle: { width: "160px" },
        },
        {
          key: "amount",
          label: "Цена",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "category",
          label: "Котегория",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "order_count",
          label: "Количество заказов",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "order_complated_count",
          label: "Количество завершенных заказов",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "created_at",
          label: "Дата создания",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "expires_at",
          label: "Дата окончания",
          sortable: true,
          thStyle: { width: "150px" },
        },
      ],
      fields: [
        { key: "id", label: "ID" },
        // {key: 'sum_for', label: 'Сумма до'},
        // {key: 'sum_after', label: 'Сумма после'},
        { key: "amount", label: "Сумма" },
        // {key: 'transaction_category', label: 'Категория'},
        // {key: 'type_name', label: 'Имя типа'},
        // {key: 'performer_login', label: 'Логин'},
        { key: "date_time", label: "Дата" },
      ],
      cancelDocument: {
        category_id: undefined,
        category: undefined,
        comment: undefined,
      },
    };
  },
  methods: {
    getDocumentInfo(data) {
      this.$store.commit("draggableTab/ADD_TAB", [
        "Документ " + data.item.document_id,
        `/promo-code/document-info`,
        data.item.document_id,
      ]);
      this.$router.push({
        name: "promo-code/document-info",
        params: {
          document_id: data.item.document_id,
          category: data.item.category,
        },
      });
    },
    resizeable() {
      var thElm;
      var startOffset;
      Array.prototype.forEach.call(
        document.querySelectorAll("table th"),
        function (th) {
          th.style.position = "relative";
          var grip = document.createElement("div");
          grip.innerHTML = "&nbsp;";
          grip.style.top = 0;
          grip.style.right = 0;
          grip.style.bottom = 0;
          grip.style.width = "5px";
          grip.style.position = "absolute";
          grip.style.cursor = "col-resize";
          grip.addEventListener("mousedown", function (e) {
            thElm = th;
            startOffset = th.offsetWidth - e.pageX;
          });
          th.appendChild(grip);
        }
      );
      document.addEventListener("mousemove", function (e) {
        if (thElm) {
          thElm.style.width = startOffset + e.pageX + "px";
        }
      });
      document.addEventListener("mouseup", function () {
        thElm = undefined;
      });
    },
    handleContextMenuEvent(item, index, event) {
      event.preventDefault();
      this.$refs.menu.open(event);
      this.cancelDocument.category_id = item.category_id;
      this.cancelDocument.category = item.category;
    },
    clicked(item, index, event) {
      this.$store.state.filterPlus.tableIndex = index;
      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(
        clickedCell
      );

      if (columnIndex < 0 || columnIndex >= this.field.length) {
        return;
      }

      const clickedField = this.field[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;

      this.$store.state.filterPlus.filterPlus = event.target.innerHTML.trim();
      this.orderId = item.id;
      let allTd = document.querySelectorAll("td");
      allTd.forEach((el) => {
        el.style.backgroundColor = "";
        el.style.color = "";
      });
      let td = event.target;
      if (event.target.tagName.toLowerCase() === "td") {
        td.style.backgroundColor = "#2979ff";
        td.style.color = "#fff";
      } else if (event.target.tagName.toLowerCase() === "span") {
        let span = event.target;
        span.parentNode.style.backgroundColor = "#2979ff";
        span.parentNode.style.color = "#fff";
      }
    },
    refresh() {
      this.showPreloader = true;
      this.$http.get(`promo-code/activations`).then((res) => {
        this.documentRow = res.data;
        this.$store.commit("pageData/setdataCount", this.documentRow.length);
        this.showPreloader = false;
        this.$store.commit("REFRESH_DATA", false);
      });
    },
    sendToParent(tableData) {
      this.documentRow = tableData;
      this.$store.commit("pageData/setdataCount", this.documentRow.length);
    },
    openFilter() {
      let allRoutes = this.$store.state.draggableTab.tabs;
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      let arrayRoutes = [];
      allRoutes.forEach((element) => {
        arrayRoutes.push(element.path);
      });
      if (arrayRoutes.includes(this.$route.path) == true) {
        this.showPreloader = true;
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.documentRow = res.data;
          this.$store.commit("pageData/setdataCount", this.documentRow.length);
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
        });
      } else if (arrayRoutes.includes(this.$route.path) == false) {
        this.$bvModal.show(this.$route.name + "filter");
      }
    },
  },
  mounted() {
    this.$store.commit("pageData/setdataCount", null);
    this.resizeable();
    this.refreshTimer = setInterval(this.resizeable, 300);
    this.openFilter();
  },
  beforeDestroy() {
    clearInterval(this.refreshTimer);
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData(val) {
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      if (val) {
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.documentRow = res.data;
          this.$store.commit("pageData/setdataCount", this.documentRow.length);
          this.$store.commit("REFRESH_DATA", false);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table.scss";
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
